@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
    font-family: 'Noto Sans Thai', sans-serif;
}

/* .offcanvas-header {
    padding: 20px 30px 20px 20px !important;
} */

.offcanvas-header .btn-close {
    padding: 11px !important;
    height: 100% !important;
    background-repeat: no-repeat !important;
}

.navicon {
    padding: 10px;
    border-radius: 7px;
    border: 2px solid #e8e8e8;
}

.pointer {cursor: pointer;}

a.mobile.navlink, .nav-item.mobile.dropdown {
    width: 100%;
}

a.btnlogin.mobile.nav-link  {
    margin: 10px 15px;
    padding: 10px 30px;
}

.navdesktop, .navmobile {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

.navbartitle {
    color: rgb(236, 195, 50);
    font-size: 25px;
    font-weight: 500;
    /* line-height: 33.89px; */
    margin-bottom: 10px;
}

.modal-body a {
    color: #fff !important;
}

.navbardesc {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #ECC332;
    background: linear-gradient(#C18A61, #ECC332);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

a.navlink, .nav-item.dropdown {
    font-weight: 600;
    font-size: 20px;
    /* line-height: 24px; */
    color: #C18A61;
    display: flex;
    align-items: center;
}

.dropdown-item {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
}

/* .navbar .container{
    max-width: 1600px;
} */

.btnlogin {
    background: #FFE071;
    border: 1px solid #C18A61 !important;
    border-radius: 7px;
    padding: 10px 25px;
    font-weight: 600 !important;
    font-size: 20px !important;
    line-height: 24px;
    text-align: center;
    color: #5A3F2B !important;
}

.btnlogin:hover{
    background-color: #dcb838;
}

.carousel-control-next, .carousel-control-prev {
    width: 5% !important;
}

.btnbrown {
    background: #5A3F2B;
    border: 1px solid #5A3F2B !important;
    border-radius: 7px;
    padding: 10px 25px;
    font-weight: 600 !important;
    font-size: 20px !important;
    line-height: 24px;
    text-align: center;
    color: #fff !important;
}

.btnbrown:hover{
    background-color: #3e2a1b;
}

.text-white {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    font-weight: 300;
    color: #FFFFFF;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.boxfooter {
    padding: 15px;
    background: #5A3F2B;
}

.bg {
    /* padding: 50px 0; */
    background-image: url(../assets/images/bg.jpg);
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
}

.font-title {
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    color: #5A3F2B;
}

.box-course {
    background: rgba(193, 138, 97, 0.57);
    
}

.box-course-desc {
    background: #FFFFFF;
    border: 3px solid #C18A61;
    border-radius: 20px;
}

.course-img {
    width: 100%;
    height: 300px;
    border-radius: 20px 0 0 20px;
    object-fit: cover;
}

.product-img {
    width: 100%;
    height: 350px;
    object-fit: contain;
}

.course-title {
    font-weight: 600;
    font-size: 32px;
    line-height: 33px;
    color: #5A3F2B;
}

.course-detail-title, .detail-title {
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    color: #5A3F2B;
}

.font-span {
    font-weight: 400;
    font-size: 20px;
    line-height: 29px;
    color: #5A3F2B;
    text-align: left;
}

.course-status {
    font-weight: 600;
    font-size: 20px;
    line-height: 33px;
    color: #1BA539;
    text-shadow: 0px 4px 0px rgba(217, 250, 198, 0.47);
}

.btn-course-status, .btn-yellow {
    background: #FFE071 !important;
    border: 1px solid #C18A61 !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
}

.btn-regis-close {
    font-weight: 600 !important;
    font-size: 18px !important;
    /* line-height: 24px !important; */
    color: #FFFFFF !important;
    background: #C18A61 !important;
    border: 1px solid #C18A61 !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
    border-radius: 7px !important;
    padding: 10px 45px !important;
}

.btn-regis-popup {
    font-weight: 600 !important;
    font-size: 18px !important;
    /* line-height: 24px !important; */
    color: #5A3F2B !important;
    background: #FFE071 !important;
    border: 1px solid #C18A61 !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 7px !important;
    padding: 10px 45px !important;
}

.btn-saveprofile {
    background: #5A3F2B !important;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px !important;
    font-weight: 600 !important;
    font-size: 20px !important;
    color: #FFFFFF !important;
}

.btn-course {
    background: #C18A61 !important;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    border-radius: 20px !important;
    font-weight: 600 !important;
    font-size: 20px !important;
    color: #FFFFFF !important;
}

a {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-decoration-line: underline;
    color: #C18A61 !important;
}

.navbar-expand-lg .navbar-nav .nav-link, .dropdown-toggle .nav-link {
    padding: 8px 20px !important;
}

.carousel-control-prev-icon {
    background-image: url(../assets/images/prev.svg) !important;
}

.carousel-control-next-icon {
    background-image: url(../assets/images/next.svg) !important;
}

.carousel-control-next {
    right: 10px !important;
}

.carousel-control-prev {
    left: 10px !important;
}

.banner {
    width: 100%;
    height: 100%;
}

.box-content{
    background: rgba(232, 194, 166, 0.7);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 35px;
}

.white-box {
    background: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 35px;
}

.img-full {
    width: 100%;
    height: auto;
    object-fit: contain;
}

.width-full {
    width: 100%;
}

.margin-auto {
    margin: 0 auto;
}

.text-left {
    text-align: left;
}

.title-white {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #FFFFFF;
}

.form-select, [type=search] {
    border: 1px solid #C18A61 !important;
    border-radius: 20px !important;
    padding: 12px 20px !important;
}

.accordion {
    --bs-accordion-bg: #fff0 !important;
}

.accordion-item:first-of-type .accordion-button, .accordion-item .accordion-button {
    background: #5A3F2B;
    border-radius: 10px;
    font-size: 24px;
}

.accordion-item {
    border: none !important;
}

.accordion-item .accordion-button {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
}

/* .accordion-item:last-of-type .accordion-button.collapsed {
    border-radius: 10px !important;
    margin-top: 15px;
} */

.accordion-button {
    font-weight: 600;
    font-size: 16px;
    line-height: 29px;
    color: #FFFFFF !important;
}

.accordion-button:not(.collapsed) {
    background: #5A3F2B !important;
    /* margin-top: 15px; */
}

.accordion-button::after {
    background-image: url(../assets/images/arrow-down.png) !important;
}

.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
    border-radius: 10px !important;
    margin-top: 15px;
}

.input-login {
    background: #FFFFFF;
    border: 1px solid #C18A61 !important;
    border-radius: 20px !important;
    padding: 12px 20px !important;
}

placeholder {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #BEBEBE;
}

.form-control:focus, .form-select:focus {
    color: #212529;
    background-color: #fff;
    border-color: #C18A61;
    outline: 0;
    box-shadow: 0 0 0 0.25rem #c18a6170 !important;
}

.btn-primary {
    background: #5A3F2B !important;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    border-radius: 20px !important;
}

a.forget-text, a.regis-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-decoration-line: underline;
    color: #5A3F2B;
}

.btn-outline-primary {
    background: #FFFFFF !important;
    border: 1px solid #C18A61 !important;
    border-radius: 20px !important;
    color: #5A3F2B !important;
}

.btn-outline-primary:hover {
    background: #5A3F2B !important;
    color: #fff !important;
}

.px-05 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
}



.img-profile{
    border-radius: 50%;
    width: 130px;
    height: 130px;
    object-fit: cover;
}

.box-profile {
    background-image: url(../assets/images/camera.png);
    border-radius: 50%;
    border: 1px solid #C18A61;
    width: 130px;
    height: 130px;
    background-repeat: no-repeat;
    background-position: center center;
    margin: 0 auto;
}

.select-img {
    background-color: #d5d0cd;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.box-camera {
    justify-content: center;
    display: flex;
    margin-left: 80px;
    margin-top: -36px;
}

/* .form-label {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #C18A61;
} */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.font-btn {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
}

.font-btn-yellow {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #5A3F2B;
}

.modal.show .modal-dialog{
    display: flex;
    height: 80vh;
    align-items: center;
}

.course-register .modal-content {
    background: linear-gradient(180deg, #FEFEF7 0%, #FEFCFC 38.54%, #FFE071 100%);
    border-radius: 0;
    border: none;
}

.text-popup {
    font-weight: 600;
    font-size: 32px;
    line-height: 47px;
    color: #5A3F2B;
}

.logo-popup {
    width: 25%;
    height: auto;
}

.title-popup-course {
    font-weight: 500;
    font-size: 32px;
    /* line-height: 10px; */
    margin-bottom: -20px;
    text-align: center;
    color: #ECC332;
}

.title-popup-course-en {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #ECC332;
    background: linear-gradient(#C18A61, #ECC332);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.popup-course-desc {
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #000000;
}

.no-shadow {
    box-shadow: none !important;
}

.btn-GG span {
    font-size: 20px!important;
    font-weight: 400!important;
}

.btn-GG div {
    margin: 0 !important;
    padding: 0 20px 0 0 !important;
    background-color: transparent !important;
}

.justifyCenter {
    justify-content: center !important;
    display: flex !important;
}

.justifycontent-space-evenly{
    justify-content: space-evenly !important;
}

.navposition {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.align-items-center {
    display: flex !important;
    align-items: center !important;
}

.text-checkbox .form-check-label {
    margin-left: 1em;
    margin-top: 4px;
}

.text-checkbox .form-check-input[type=checkbox] {
    border-radius: 0!important;
    background-color: #ffffff;
    border: 3px solid #000000;
}

.text-checkbox .form-check-input:checked[type=checkbox] {
    background-image: url('../assets/images/tick.png');
}

.btn-popup-course {
    font-weight: 600 !important;
    font-size: 20px !important;
    line-height: 24px !important;
    color: #FFFFFF !important;
    background: #5A3F2B !important;
    border: 1px solid #C18A61 !important;
    border-radius: 7px !important;
    padding: 10px 45px !important;
}

.text-checkbox {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #000000;
}


.modal {
    --bs-modal-width: 630px !important;
}

.register .modal-content {
    border: 5px solid #5A3F2B !important;
    border-radius: 25px !important;
}

.btn-close {
    background: transparent url(../assets/images/close.png) !important;
    background-repeat: no-repeat;
}

.btn-close {
    opacity: 1 !important; 
}

.modal-header .btn-close {
    padding: 11px !important;
}

.regis-lable {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #C18A61;
}

.course-lable {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #5A3F2B;
}

.lable-text {
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: #5A3F2B
}
.alignItems-center {
    display: flex;
    align-items: center;
}

.course-detail{
    background: rgba(232, 194, 166, 0.66);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.course-text {
    font-size: 20px;
    line-height: 33px;
    color: #5A3F2B;
}

.box-product {
    background: #FFFBED;
    border: 3px solid #C18A61;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

.p-price {
    font-weight: 600;
    font-size: 32px;
    line-height: 33px;
    color: #5A3F2B;
}

.course-enroll .modal-dialog {
    max-width: 70%;
    display: flex !important;
    justify-content: center;
    /* overflow: hidden; */
}

.popup-qr-payment .modal-dialog {
    max-width: 27%
}

/* .modal {
    overflow: scroll !important;
} */

.course-enroll .modal-content {
    border-radius: 0;
}

.modal-open {
    padding-right: 0 !important;
}

.course-register .modal-dialog {
    padding-right: 0 !important;
    display: flex !important;
    justify-content: center;
    /* overflow: hidden; */
    height: 90%;
}

.modal-header-enroll {
    border-bottom: 0 !important;
    background: rgba(232, 194, 166, 0.66);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0 !important;
}

.modal-body-enroll {
    background-image: url(../assets/images/SL_111720_38050_100_03.png) !important;
    background: rgba(232, 194, 166, 0.66);
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
}

.modal-body-enroll-detail {
    background: rgba(193, 138, 97, 0.32);
    border-radius: 10px;
}

.enroll-edit {
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 22px !important;
    text-align: center;
    color: #5A3F2B !important;
    background: rgba(193, 138, 97, 0.32);
    border: 1px solid #C18A61 !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    border-radius: 7px;
}

.enroll-course {
    background-color: #ffffff87;
    border-radius: 20px;
}

.table-enroll-course {
    background: #FFFFFF;
    border: 1px solid #C18A61;
    border-radius: 20px;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #C18A61;
    text-align: center;
}

.message {
    color: #ff0000;
    font-style: italic;
    text-align: left;
}

/* table {
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
  } */
  
  /* table caption {
    font-size: 1.5em;
    margin: .5em 0 .75em;
  } */

  /* thead tr {
    border: 1px solid #C18A61;
  } */
  
  /* table tr {
    background-color: #f8f8f8;
    border: 1px solid #ddd;
    padding: .35em;
  } */
  
  /* table th,
  table td {
    padding: .625em;
    text-align: center;
  } */
  
  /* table th {
    font-size: .85em;
    letter-spacing: .1em;
    text-transform: uppercase;
  } */

  .enroll-course-name {
    font-weight: 600;
    font-size: 20px;
    line-height: 33px;
    text-decoration-line: underline;
    color: #5A3F2B;
  }

.form-control:disabled {
    background-color: #c18a6185 !important;
    opacity: 1;
}

.enroll-course-detail {
    font-weight: 400;
    font-size: 20px;
    line-height: 33px;
}

.status-waiting {
    font-weight: 600;
    font-size: 20px;
    line-height: 33px;
    color: #FCA702;
}

.text-confirm {
    font-weight: 600;
    font-size: 20px;
    line-height: 33px;
    color: #1BA539;
    text-shadow: 0px 4px 0px rgba(217, 250, 198, 0.47);
}

.status-succeed {
    font-weight: 600;
    font-size: 20px;
    line-height: 33px;
    text-align: center;
    color: #2166CE;
}

.status-cancel {
    font-weight: 600;
    font-size: 20px;
    line-height: 33px;
    text-align: center;
    color: #FF0000;
}

.course-status {
    font-weight: 600;
    font-size: 20px;
    line-height: 33px;
    text-align: center;
    color: #5A3F2B;
}

.btn-cancel {
    background: #8B8B8B !important;
    border: 1px solid #8B8B8B !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    border-radius: 7px !important;
    font-size: 18px !important;
    line-height: 22px !important;
    text-align: center  !important;
    color: #FFFFFF !important;
    width: 35%;
    height: 40px;
}

.btn-enroll-course-close {
    background: #C18A61 !important;
    border: 1px solid #C18A61!important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    border-radius: 7px !important;
    font-size: 18px !important;
    line-height: 22px !important;
    text-align: center  !important;
    color: #FFFFFF !important;
    width: 35%;
    height: 40px;
}

.dropdown-item.active, .dropdown-item:active {
    background-color: #e8e8e8 !important;
}
  
/* general styling */
body {
    font-family: "Open Sans", sans-serif;
    line-height: 1.25;
}

/* Backend */
.backend-title {
    background: #efd6c4; /* For browsers that do not support gradients */        
    background: -webkit-linear-gradient(90deg, rgba(232, 194, 166, 0.66)); /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(90deg, rgba(232, 194, 166, 0.66)); /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(90deg, rgba(232, 194, 166, 0.66)); /* For Firefox 3.6 to 15 */
    background:  linear-gradient(90deg, rgba(232, 194, 166, 0.66) 7.74%, rgba(232, 194, 166, 0) 45.59%); /* Standard syntax (must be last) */
    padding: 0.5em;
}

.backend-body {
    margin: 2em 0;
}


.input-profile {
    border: 2px solid #5a3f2b;
    padding: 8px 15px;
    border-radius: 25px;
}

.btnbrown a.dropdown-toggle.nav-link {
    color: #fff !important;
}

.btnbrown.nav-item.dropdown {
    padding: 0;
    /* font-size: 17px !important; */
    padding: 5px;

}

    @media (min-width: 1200px) {
        .navmobile {
            display: none;
        }

        .navdesktop {
            display: block;
        }

        a.navlink, .nav-item.dropdown {
            font-size: 20px !important;
        }
    }

    @media (max-width: 1199.98px) {
        .product-img {
            height: 260px;
        }

        .popup-qr-payment .modal-dialog {
            max-width: 45%
        }

        .navmobile {
            display: block;
        }

        .navdesktop {
            display: none;
        }
    }

    @media (min-width: 992px) and (max-width: 1199.98px) {
        .navbartitle {
            font-size: 20px;
        }

        .navbar-expand-lg .navbar-nav .nav-link, .dropdown-toggle .nav-link {
            padding: 8px 10px !important;
        }

        a.navlink, .nav-item.dropdown{
            font-size: 16px;
        }

        .btnlogin {
            padding: 10px 20px;
            font-size: 16px;
        }
        
        .navbar>.container {
            max-width: 1000px;
        }

        .table-enroll-course {
            font-size: 18px;
        }

        a.mobile.navlink, .nav-item.mobile.dropdown {
            font-size: 18px;
        }
        
    }

    @media (min-width: 992px) {
        .navmobile {
            display: none;
        }

        .navdesktop {
            display: block;
        }

        a.navlink, .nav-item.dropdown {
            font-size: 17px ;
        }
        
    }

    @media (max-width: 991.98px) { 
        .popup-qr-payment .modal-dialog {
            max-width: 55%
        }

    }

    @media (min-width: 768px) and (max-width: 991.98px) {
        
        .navmobile {
            display: block;
        }

        .navdesktop {
            display: none !important;
        }

    }

    @media (max-width: 767.98px) {
        .product-img {
            height: 190px;
        }

        .table-enroll-course {
            font-size: 18px;
        }

        .popup-qr-payment .modal-dialog {
            max-width: 100%
        } 
    
    }

    @media (min-width: 768px) {
        .table-enroll-course {
            font-size: 18px;
        }

        .navmobile {
            box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
        }
    }

    @media (min-width: 576px) and (max-width: 767.98px) {
        .navmobile {
            display: block;
        }

        .navdesktop {
            display: none !important;
        }
    }

    @media (max-width: 575.98px) {
        .course-img {
            border-radius: 20px 20px 0 0;
        }

        .product-img {
            height: 160px;
        }

        .img-full {
            height: auto;
        }

        .navbartitle {
            font-size: 21px;
        }

        .px-05 {
            padding-right: 1.5rem !important;
            padding-left: 1.5rem !important;
        }

        .text-popup {
            font-size: 25px;
            line-height: 32px;
        }

        .form-label {
            font-size: 19px;
        }

        .lable-text {
            font-size: 17px;
        }

        .form-check-inline {
            display: block !important;
            margin-left: 1rem;
        }

        .table-enroll-course {
            font-size: 18px;
        }

        .navmobile {
            display: block;
            padding: 10px;
        }

        .navdesktop {
            display: none !important;
        }
    }

    @media screen and (max-width: 600px) {
        table {
        border: 0;
        }
    
        table caption {
        font-size: 1.3em;
        }
        
        table thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
        }
        
        table tr {
        border-bottom: 3px solid #ddd;
        display: block;
        margin-bottom: .625em;
        }
        
        table td {
        border-bottom: 1px solid #ddd;
        display: block;
        font-size: .8em;
        text-align: right;
        }
        
        table td::before {
        /*
        * aria-label has no advantage, it won't be read inside a table
        content: attr(aria-label);
        */
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: uppercase;
        }
        
        table td:last-child {
        border-bottom: 0;
        }
    }